import React from 'react'
import { BooleanInput, AutocompleteArrayInput, TextInput, required, maxLength, SelectInput, ReferenceArrayInput } from 'react-admin'
import LanguagesNav from '../../../../components/languagesNav'
import { Grid } from '@material-ui/core'
import { ImageInput } from '../../../../components/inputs'
import { getResource } from '../../../../config/resources'
import { TextField } from '@material-ui/core'

class SponsorsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      countries: null,
    }
  }

  componentDidMount() {
    if (this.props.record.countries?.includes('GLOBAL')) {
      this.setState({ countries: 'GLOBAL' })
    }
  }

  handleChange = (e) => {
    this.setState({
      countries: e.target.value,
    })
  }

  render() {
    const { record } = this.props

    return (
      <LanguagesNav {...this.props}>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <SelectInput
              source="type"
              choices={[
                { id: 'dynamic', name: 'Dynamic' },
                // { id: 'fixed', name: 'Fixed' },
              ]}
              alwaysOn
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            {this.state.countries === 'GLOBAL' ? (
              <TextField value={this.state.countries} label="Countries" onChange={this.handleChange} />
            ) : (
              <ReferenceArrayInput fullWidth source="countries" label="Countries" reference={getResource('transversal', 'countries')}>
                <AutocompleteArrayInput optionText="name" />
              </ReferenceArrayInput>
            )}
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <TextInput source="name" label="Name" fullWidth validate={[required(), maxLength(255)]} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInput fullWidth source="url" label="Url" validate={[required()]} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ImageInput source="image" label="Design" accept="image/*" validate={[required()]} />
          </Grid>
          <Grid item xs={12}>
            <BooleanInput source="active" label="Active" />
          </Grid>
        </Grid>
      </LanguagesNav>
    )
  }
}

export default SponsorsForm
