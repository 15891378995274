import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceField,
  ImageField,
  EditButton,
  Responsive,
  SimpleList,
  DeleteButton,
  ArrayField,
  Filter,
  TextInput,
} from 'react-admin'
import { ListActions, TableRowList } from '../../../components'
import { getResource } from '../../../config/resources'
import { withStyles } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { Grid, Chip } from '@material-ui/core'

const styles = (theme) => ({
  deleteStyle: {
    color: fade(theme.palette.error.main, 0.9),
    padding: '2px 6px 2px 2px',
  },
})

const ListCountries = (props) => {
  const { data } = props
  return (
    <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap', marginTop: '8px' }}>
      {Object.keys(data).map((country, index) => {
        return <Chip key={index} label={country} />
      })}
    </div>
  )
}

const SponsorsFilters = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={8}>
          <TextInput source="name" label="Name" alwaysOn fullWidth />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<SponsorsFilters />} />
}

const SponsorsList = ({ classes, ...rest }) => {
  return (
    <List title="Sponsors" bulkActions={false} actions={<Actions />} {...rest}>
      <Responsive
        small={
          <SimpleList
            leftAvatar={(record) => (
              <ReferenceField
                source="image"
                reference={getResource('assets', 'images')}
                basePath={getResource('assets', 'images')}
                linkType={false}
                record={record}
                allowEmpty
              >
                <ImageField mini source="asset.fileDir" title="Image Splash Screen" />
              </ReferenceField>
            )}
            primaryText={(record) => record.name}
          />
        }
        medium={
          <Datagrid>
            <ReferenceField label="Image" source="image" reference={getResource('assets', 'images')} linkType={false} sortable={false} allowEmpty>
              <ImageField source="asset.fileDir" title="Sponsor image" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="type" />
            <BooleanField source="active" label="Active" />
            <ArrayField source="countries" label="Countries" sortable={false}>
              <ListCountries />
            </ArrayField>
            <TableRowList>
              <EditButton />
              <DeleteButton className={classes.deleteStyle} />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default withStyles(styles)(SponsorsList)
